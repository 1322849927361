<template>
  <v-expansion-panel>
      <template>
          <v-expansion-panel-header class="mt-2">
                Adminsitración de Gastos

              <template v-slot:actions>
                    <v-btn class="mr-5" @click.native.stop="reload()" icon small>
                        <v-icon color="primary">mdi-reload</v-icon>
                    </v-btn>
                    <v-btn @click="$store.dispatch('deleteWindowByUuid',{uuid : win.uuid})" icon small>
                        <v-icon color="error">mdi-close</v-icon>
                    </v-btn>
              </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
              <v-tabs v-model="tab" dark background-color="primary" icons-and-text>
                  <v-tabs-slider color="red"></v-tabs-slider>
                  <!--v-tab href="#tab-1" v-if="$store.getters['rolesUser/getCustomRole'](206)">
                      Administración de Gastos
                      <v-icon>mdi-bank-minus</v-icon>
                  </v-tab-->

                  <v-tab href="#tab-2" v-if="$store.getters['rolesUser/getCustomRole'](211)">
                      Proveedores de Gastos
                      <v-icon>mdi-account-supervisor-circle</v-icon>
                  </v-tab>

                  <v-tab href="#tab-3" v-if="$store.getters['rolesUser/getCustomRole'](216)">
                      Ingreso de Gastos
                      <v-icon>mdi-account-cash</v-icon>
                  </v-tab>
                  
                  <v-tab href="#tab-5" v-if="$store.getters['rolesUser/getCustomRole'](218)">
                      Ingreso masivo de gastos
                      <v-icon>mdi-account-details</v-icon>
                  </v-tab>

                  <v-tab href="#tab-4" v-if="$store.getters['rolesUser/getCustomRole'](218)">
                    Historial de facturación de gastos
                    <v-icon>mdi-clipboard-text-clock</v-icon>
                  </v-tab>


                  
              </v-tabs>

              <v-tabs-items v-model="tab" touchless>
                  <!--v-tab-item value="tab-1">
                      <v-card flat>
                          <v-card-text class="pa-1">
                                    <ExpenseManagment :win="win" :key="key" />               
                          </v-card-text>
                      </v-card>
                  </v-tab-item-->

                  <v-tab-item value="tab-2">
                      <v-card flat>
                          <v-card-text class="pa-1">
                                    <ExpenseManagmentProviders :win="win" :key="key" />               
                          </v-card-text>
                      </v-card>
                  </v-tab-item>

                  <v-tab-item value="tab-3">
                      <v-card flat>
                          <v-card-text class="pa-1">
                                    <ExpenseManagmentEntry :win="win" :key="key" />               
                          </v-card-text>
                      </v-card>
                  </v-tab-item>

                  <v-tab-item value="tab-4">
                    <v-card flat>
                        <v-card-text class="pa-1">
                                  <ExpenseManagmentEntryHistory :win="win" :key="key" />               
                        </v-card-text>
                    </v-card>
                </v-tab-item>

                  <v-tab-item value="tab-5">
                    <v-card flat>
                        <v-card-text class="pa-1">
                                  <ExpenseManagmentMassiveEntry :win="win" :key="key" />               
                        </v-card-text>
                    </v-card>
                </v-tab-item>
              </v-tabs-items>
          </v-expansion-panel-content>
      </template>
      
  </v-expansion-panel>
</template>

<script>
  
  //import {mapState} from 'vuex';
  //import ExpenseManagment from '../partial/ExpenseManagment.vue';
  import ExpenseManagmentEntry from '../partial/ExpenseManagmentEntry.vue';
  import ExpenseManagmentEntryHistory from '../partial/ExpenseManagmentEntryHistory.vue';
  import ExpenseManagmentProviders from '../partial/ExpenseManagmentProviders.vue'
  import ExpenseManagmentMassiveEntry from '../partial/ExpenseManagmentMassiveEntry.vue'
  export default {
      name: "Costs",
      components: {
        //ExpenseManagment,
        ExpenseManagmentEntry,
        ExpenseManagmentProviders,
        ExpenseManagmentEntryHistory,
        ExpenseManagmentMassiveEntry,
      },
      props: ['win'],
      data() {
          return {
              panel: 0,
              tab: null,
              key: 0,
              
              
          }
      },
      computed:{
          /*...mapState('customerData', {
              tab: 'tab-1'
          }),*/
      },
      methods: {
          reload() {
              this.key++;
              setTimeout(() => {
              window.scrollTo(0, this.$store.getters.getScrollTop)
              //alert(this.$store.getters.getScrollTop)
              },300);
          },
      },

  }
</script>

<style scoped>

</style>
<template>
  <div>
    <div class="white box">
      <!-- bar -->
      <v-card flat class="pa-4 bar mb-10" v-if="$store.getters['rolesUser/getCustomRole'](213)">
        <div class="d-flex justify-end align-center">
          <v-btn
            small
            color="primary"
            @click.prevent="create"
            style="margin-right: 0.3rem"
          >
            <v-icon> mdi-plus</v-icon>
          </v-btn>
        </div>
      </v-card>
      <!-- bar -->
      <v-row>
        <v-col cols="12">
          <h1>Administración de proveedores de gastos</h1>
        </v-col>
      </v-row>

      <!-- Table -->
    
        <ProvidersTable :newProviders="providers" @handleProvider="modify" v-if="$store.getters['rolesUser/getCustomRole'](223)"/>
      
      <!-- Table -->

      <!-- Dialog -->
      <v-dialog v-model="showForm" scrollable width="75%" >
        <ProviderDialog
          :newSelectedProvider="selectedProvider"
          :action="action"
          @handleAction="handleAction"
        />
      </v-dialog>
      <!-- Dialog -->
    </div>

    <!-- Alert -->
    <alerts
      v-if="alert.show"
      v-on:close_alert="closeAlert"
      v-on:accept_alert="acceptAlert"
      :properties="alert"
      class="deep-alert"
    >
    </alerts>
    <!-- Alert -->
  </div>
</template>

<script>
import Alerts from "@/components/Alerts";
import ProvidersTable from "../ExpenseProvidersTable.vue";
import ProviderDialog from "../ExpenseProviderDialog.vue";

export default {
  name: "ExpenseManagmentProviders",

  components: { ProvidersTable, ProviderDialog, Alerts },

  props: ["win"],

  data() {
    return {
      action: {
        ADD: true,
        EDIT: false,
        DEL: false,
      },
      showForm: false,
      providers: null,
      selectedProvider: null,
      alert: {
        type: "success",
        show: false,
        header: "",
        body: "",
      },
    };
  },

  mounted() {
    this.getAllProviders();
  },

  methods: {
    acceptAlert() {
      this.alert.show = false;
    },
    closeAlert() {
      this.alert.show = false;
    },
    showAlert(type, header, body, options = null) {
      type = type == null ? "danger" : type;
      this.alert.type = type;
      this.alert.header = header;
      this.alert.body = body;
      this.alert.show = true;
      this.alert.options = options != null ? options : null;
    },
    getAllProviders() {
      this.$API.accountingexpenses
        .getAllProvidersExpenses()
        .then(({ data }) => {
          this.providers = data;
          console.log(this.providers)
        })
        .catch((error) => console.log(error));
    },
    addProvider(data) {
      if (data) {
        this.$API.accountingexpenses
          .saveProvidersExpenses(data)
          .then(() => {
            this.showAlert(
              "success",
              "Proveedor de gastos agregado",
              `Se agregó el proveedor de gastos correctamente`
            );
            this.getAllProviders();
          })
          .catch((error) => {
            this.showAlert(
              "danger",
              "Error",
              `No se pudo crear el proveedor de gastos: ${error}`
            );
          });
      } else {
        this.showAlert("danger", "Error", "No se pudo crear el proveedor de gastos");
      }
    },
    editProvider(data) {
      if (data) {
        this.$API.accountingexpenses
          .updateProvidersExpenses(data)
          .then(() => {
            this.showDataForm = false;
            this.showAlert(
              "success",
              "Proveedor de gastos",
              `Se edito el gasto correctamente`
            );
            this.getAllProviders();
          })
          .catch((error) => {
            this.showAlert(
              "danger",
              "Error",
              `No se pudo editar el proveedor de gastos: ${error}`
            );
            this.showDataForm = false;
          });
      } else {
        this.showAlert("danger", "Error", "No se pudo editar el proveedor de gastos");
      }
    },
    deleteProvider({ ID_Provider }) {
      this.$API.accountingexpenses
        .deleteProvidersExpenses({ ID_Provider })
        .then(() => {
          this.showAlert(
            "success",
            "Gasto eliminado",
            `Se eliminó el gasto correctamente`
          );
          this.getAllProviders();
        })
        .catch((error) => {
          this.showAlert("danger", "Error", `No se pudo eliminar el gasto: ${error}`);
        });
    },
    errorProvider(data) {
      if (!data.ID_AccountCatalog) {
        this.showAlert(
          "danger",
          "Error",
          "No se seleciono una cuenta contable válida a la hora de agregar."
        );
      } else {
        this.showAlert("danger", "Error", "Vuelva a agregar los datos correctamente.");
      }
    },
    create() {
      this.showDialog();
      this.action.ADD = true;
    },
    modify(data) {
      this.showDialog();
      this.selectedProvider = data;
      this.action.EDIT = true;
      this.action.DEL = true;
    },
    handleAction({ action, ...data } = {}) {
      this.closeDialog();
      if (action === "add") {
        this.addProvider(data);
      }
      if (action === "edit") {
        this.editProvider(data);
      }
      if (action === "del") {
        this.deleteProvider(data);
      }
      if (action === "error") {
        this.errorProvider(data);
      }
    },
    closeDialog() {
      this.showForm = false;
      this.clean();
    },
    showDialog() {
      this.showForm = true;
      this.clean();
    },
    clean() {
      this.action = {
        ADD: false,
        EDIT: false,
        DEL: false,
      };
      this.selectedProvider = null;
    },
  },
};
</script>

<style scoped>
.btnsave {
  background-color: #00a178 !important;
  color: white;
}

.btnclean {
  background-color: #f29d35 !important;
  color: white;
  margin: 0;
  padding: 0;
}

.btn-add {
  background-color: #3498db !important;
  color: white;
}

.btndisable {
  background-color: #41464c !important;
  color: white;
}

.box {
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid;
  border-color: black;
}

.bar {
  width: 100%;
  background-color: rgb(60, 60, 60) !important;
  color: white !important;
  border-style: solid;
  border-color: black;
  border-radius: 15px !important;
  margin-top: 1rem !important;
}
</style>

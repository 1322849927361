<template>
  <v-card class="border" style="max-height:inherit;overflow-y:scroll">
    <v-btn @click="closeExpense" elevation="0" color="red" class="close-btn align-self-end"
      ><v-icon>mdi-close</v-icon></v-btn
    >
    <v-card-title v-if="action.ADD" class="title"> Seleccionar cuenta contable </v-card-title>
    <v-card-title v-else class="title"> Modificar gasto </v-card-title>

    <v-form>
      <v-card-text>
        <v-row>
          <!--v-col cols="12" md="12" class="mt-5 mb-3">
            <v-text-field
              v-model="expenseName"
              @input="expenseName = expenseName.toUpperCase()"
              outlined dense
              label="Nombre del gasto"
              :rules="rules"
            />
          </v-col-->
          <!-- TreeList - Accounts -->
          <GeneralFilter
            entity="lista"
            :filterEndpoint="
              $API.branchaccountcatalog.getActiveFilterBranchAccountCatalog
            "
            :search="search"
            @emptyFilter="getAllAcounts"
            @filtered="filterHandler"
            style="width: 100%;"
          >
          <!--v-row :class="`d-flex flex-wrap`" style="justify-content: flex-end !important;">
            <v-col class="col-7-input" style="display: flex; flex-wrap: nowrap;" :cols="twoSlots ? 10 : 12"  md="8">
              <v-text-field
              outlined
              dense
              label="Buscar..."
              v-model="filter"
              @input="handleEmptyFilter"
              @keyup.enter="filterHandler"
              ></v-text-field>
              <v-btn ref="filterButton" class="btn-add" style="margin-left: 2%;" @click="filterHandler"
                ><v-icon>mdi-magnify</v-icon></v-btn>
            </v-col>
          </v-row-->
          <div style="overflow-y: scroll; height: 350px; padding:10px">
            <DxDataGrid
              :scrolling="{useNative: true}"
              :data-source="accountList"
              :show-row-lines="showRowLines"
              :show-borders="showBorders"
              :row-alternation-enabled="rowAlternationEnabled"
              :key-field="true"
              :column-auto-width="true"
              :word-wrap-enabled="true"
              :paging="{enabled: false}"
              id="tasks"
              parent-id-expr="id_parent"
              @selection-changed="onSelectionChanged"
            >
             
              <DxSelection mode="single" />
              <DxScrolling row-rendering-mode="virtual"/>
              <DxColumn
                data-field="account_code"
                caption="Código"
                :width="columnWidth"
              ></DxColumn>
              <DxColumn data-field="account_description"  :width="columnWidth" caption="Nombre"></DxColumn>
              <DxColumn data-field="SelectedItem" caption="Mov" :width="columnWidth"></DxColumn>
              <DxPager :showPageSizeSelector="true" :allowedPageSizes="[10, 30, 50]" />
              <DxPaging :page-size="10" />
            </DxDataGrid>
          </div>
          </GeneralFilter>
          <!-- TreeList - Accounts -->
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn class="btn-add" v-if="action.DEL && $store.getters['rolesUser/getCustomRole'](210)" @click="deleteExpense">Eliminar</v-btn>
        <v-spacer></v-spacer>
        <v-btn class="btnsave" v-if="action.ADD && $store.getters['rolesUser/getCustomRole'](208)" @click="addExpenses">Seleccionar</v-btn>
        <v-btn class="btnsave" v-if="action.EDIT&& $store.getters['rolesUser/getCustomRole'](209)" @click="editExpenses">Editar</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
 import GeneralFilter from "@/components/GeneralFilter";
import { DxColumn, DxPager, DxPaging, DxScrolling, DxDataGrid } from "devextreme-vue/data-grid";
import { DxSelection } from "devextreme-vue/tree-list";

export default {
  name: "ExpenseDialog",

  props: ["win", "newAccountList", "newSelectedExpense", "action"],

  components: {
    DxSelection,
    DxColumn,
    DxPager,
    DxPaging,
    DxScrolling,
    DxDataGrid,
    GeneralFilter
  },

  data() {
    return {
      rules: [(v) => v !== null || "Valor es requerido"],
      selectedRowKeys: [],
      expenseName: this.newSelectedExpense ? this.newSelectedExpense.ExpenseName : null,
      accountItem: null,
      accountList: [...this.newAccountList],
      alert: {
        type: "success",
        show: false,
        header: "",
        body: "",
      },
      showForm: false,
      showDataForm: false,
      search: {
        filter: null,
      },
      showRowLines: true,
      showBorders: true,
      rowAlternationEnabled: true,
    };
  },

  watch: {
    newSelectedExpense(data) {
      if (data != undefined && data != null) {
        this.expenseName = this.newSelectedExpense.ExpenseName;
      } else {
        this.expenseName = null;
      }
    },
  },

  mounted() {
    this.formatAccounts();
    console.log("se creo");
    console.log(this.newSelectedExpense);
  },

  computed: {
    ID_Business() {
      return JSON.parse(localStorage.getItem("user")).businessid != undefined
        ? JSON.parse(localStorage.getItem("user")).businessid
        : 0;
    }, 
    columnWidth() {
    
    return this.$vuetify.breakpoint.smAndDown ? '150px' : 'auto !important';
  },
    ID_Branch() {
      return JSON.parse(localStorage.getItem("user")).branch != undefined
        ? JSON.parse(localStorage.getItem("user")).branch
        : 0;
    },
  },

  methods: {
    filterHandler(data) {
      console.log(data);
      this.accountList = data.data;
      this.formatAccounts();
    },
    formatAccounts() {
      for (const index in this.accountList) {
        this.accountList[index].id = this.accountList[index].id_account;
      }
    },
    getAllAcounts() {
      this.accountList = [...this.newAccountList]
      this.formatAccounts();
    },
    getAccountName(id) {
      return this.accountList.find((account) => id === account.id_account);
    },
    onSelectionChanged(e) {
      if (e.selectedRowsData) {
        this.accountItem = e.selectedRowsData[0].SelectedItem
          ? e.selectedRowsData[0]
          : null;
      }
      console.log(e.selectedRowsData[0]);
    },
    showData({ ID, ExpenseName, ID_AccountCatalog, ...rest }) {
      this.showDataForm = true;
      this.selectedExpense = {
        ExpenseName,
        ID,
        ID_AccountCatalog,
      };
      this.expenseName = ExpenseName;
      console.log(ID, ExpenseName, ID_AccountCatalog);
      console.log(rest);
    },
    sendDataSelection() {
      this.showDataForm = false;
      console.log("item:", this.accountItem);
      if (this.accountItem) {
        console.log("gastos", this.expenses);
        this.expenses.find((expense) => {
          if (expense.ID === this.selectedExpense.ID) {
            expense.ID_AccountCatalog = this.accountItem.id_account;
            expense.ExpenseName = this.expenseName;
          }
        });
      } else {
        this.showAlert("danger", "Error", "Seleccione una cuenta valida");
      }
    },
    addExpenses() {
      this.$emit("handleAction", this.dataToSend("add"));
      this.clean();
    },
    editExpenses() {
      this.$emit("handleAction", this.dataToSend("edit", this.newSelectedExpense));
      this.clean();
    },
    deleteExpense() {
      this.$emit("handleAction", this.dataToSend("del", this.newSelectedExpense));
      this.clean();
    },
    closeExpense() {
      this.$emit("handleAction");
      this.clean();
    },
    dataToSend(action, selectedExpense) {
      let id_account = null;

      if (this.accountItem) {
        id_account = this.accountItem.id_account;
      }

      if (selectedExpense) {
        // Edit or delete
        return {
          // It should work if (action is DELETE) OR ( id account exist AND expenseName exist )
          action:
            action === "del" ||
            ((id_account || selectedExpense.ID_AccountCatalog))
              ? action
              : "error",
          ID: selectedExpense.ID,
          ExpenseName: this.expenseName,
          ID_AccountCatalog: id_account ?? selectedExpense.ID_AccountCatalog,
          ID_Business: this.ID_Business,
          ID_Branch: this.ID_Branch,
        };
      } else {
        // Add
        return {
          action: id_account ? action : "error",
          ExpenseName: this.expenseName,
          ID_AccountCatalog: id_account,
          CatalogName: this.accountItem?.account_description,
          AccountCode: this.accountItem?.account_code,
          ID_Business: this.ID_Business,
          ID_Branch: this.ID_Branch,
        };
      }
    },

    clean() {
      this.expenseName = null;
      this.accountItem = null;
    },
  },
};
</script>

<style scoped>
.sized {
  min-width: 400px !important;
}

.close-btn {
  color: white !important;
  position: relative !important;
  float: right !important;
}

.border {
  border-radius: 15px !important;
}

.btnsave {
  background-color: #00a178 !important;
  color: white;
}

.btnclean {
  background-color: #f29d35 !important;
  color: white;
  margin: 0;
  padding: 0;
}

.btn-add {
  background-color: #3498db !important;
  color: white;
}

.btndisable {
  background-color: #41464c !important;
  color: white;
}

.btndelete {
  background-color: red !important;
  color: white;
}

.title {
  font-weight: bold !important;
  font-size: 25px !important;
}
</style>

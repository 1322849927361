<template>
  <div>
    <GeneralFilter
      :filterEndpoint="$API.accountingexpenses.getAllProvidersFiltered"
      :search="search"
      @emptyFilter="getAllProviders"
      @filtered="filterHandler"
    >
      <DxDataGrid
        :data-source="providersActivities"
        :show-row-lines="true"
        :show-borders="true"
        :row-alternation-enabled="true"
        :scrolling="{ useNative: true }"    
                    :column-auto-width="true"
      >
        
        <DxPaging :page-size="10" />
        <DxColumn :width="columnWidth" cell-template="showdata" caption="" v-if="$store.getters['rolesUser/getCustomRole'](212)" ></DxColumn>
        <DxColumn :width="columnWidth"  v-if="showColumn" data-field="ID_Provider" caption="ID"></DxColumn>
        <DxColumn :width="columnWidth"  data-field="ProviderName" caption="Proveedor"></DxColumn>
        <DxColumn :width="columnWidth"  data-field="ProviderLegalAddress" caption="Dirección legal"></DxColumn>
        <DxColumn :width="columnWidth" 
          v-if="showColumn"
          data-field="ProviderLegalSectorName"
          caption="Actividad economica"
        ></DxColumn>
        <DxColumn :width="columnWidth" 
          v-if="showColumn"
          data-field="ProviderLegalTaxPayerSize"
          caption="Tamaño de contribuyente"
        ></DxColumn>
        <DxColumn :width="columnWidth" 
          v-if="showColumn"
          data-field="ProviderLegalEntity"
          caption="Entidad juridica"
        ></DxColumn>
        <DxColumn :width="columnWidth" 
          v-if="showColumn"
          data-field="ProviderLegalNRC"
          caption="NRC Legal"
        ></DxColumn>
        <DxColumn :width="columnWidth" 
          v-if="showColumn"
          data-field="ProviderLegalNIT"
          caption="NIT Legal"
        ></DxColumn>
        <template #showdata="{ data }">
          <a href="#" @click.prevent.stop="handleProvider(data.data)">
            <v-icon>mdi-{{ icon }}</v-icon>
          </a>
        </template>
      </DxDataGrid>
    </GeneralFilter>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import GeneralFilter from "@/components/GeneralFilter";
import { DxDataGrid, DxColumn,  DxPaging } from "devextreme-vue/data-grid";

export default {
  name: "ExpenseProvidersTable",

  components: { DxDataGrid, DxColumn,  DxPaging, GeneralFilter },

  props: {
    newProviders: {
      type: Array,
    },
    icon: {
      type: String,
      default: "eye",
    },
  },

  data() {
    return {
      providers: null,
      showForm: false,
      search: {
        filter: null,
      },
    };
  },

  mounted() {
    this.getAllProviders();
  },

  watch: {
    newProviders(data) {
      if (data != null || data != undefined) {
        this.providers = [...this.newProviders];
      }
    },
  },

  computed: {
    ...mapState("rolesUser", {
      rolesStatus: (state) => state.roles,
    }),
    ...mapGetters(["getCatalogMH"]),
    activities() {
      return this.getCatalogMH("ActividadEconomica");
    },
    providersActivities() {
      return this.providers
        ? this.providers.map((provider) => {
            if (provider.ProviderLegalSector) {
              const activity = this.activities.find(
                (activity) => activity.Codigo === provider.ProviderLegalSector
              );
              provider.ProviderLegalSectorName = activity ? activity.Valores : "";
            } else {
              provider.ProviderLegalSectorName = "";
            }
            return provider;
          })
        : [];
    },
  
    columnWidth() {
    
    return this.$vuetify.breakpoint.smAndDown ? '150px' : 'auto !important';
  },
    showColumn() {
      return this.icon === "eye";
    },
  },

  methods: {
    getAllProviders() {
      this.providers = [...this.newProviders];
    },
    filterHandler({ data }) {
      this.providers = data[0];
    },
    handleProvider(data) {
      this.selectedProvider = data;
      this.$emit("handleProvider", this.selectedProvider);
    },
  },
};
</script>

<style scoped></style>

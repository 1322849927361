<template>
  <div style="z-index: 200">
    <block v-if="loading" />
    <div
      class="white"
      style="
        border-radius: 10px;
        padding: 20px;
        padding-top: 50px;
        margin-bottom: 20px;
        border: 1px solid;
        border-color: black;
      "
    >
      <v-row>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="provider.ProviderName"
            outlined
            dense
            label="Proveedor"
          />
        </v-col>

        <v-col cols="12" md="3">
          <v-text-field
            v-model="provider.ProviderLegalAddress"
            outlined
            dense
            label="Dirección legal"
          />
        </v-col>

        <v-col cols="12" md="3">
          <v-text-field
            v-model="provider.ProviderLegalSector"
            outlined
            dense
            label="Actividad economica"
          />
        </v-col>

        <v-col cols="12" md="3">
          <v-text-field
            v-model="provider.ProviderLegalTaxPayerSize"
            outlined
            dense
            label="Tamaño de contribuyente"
          />
        </v-col>

        <v-col cols="12" md="3">
          <v-text-field
            v-model="provider.ProviderLegalEntity"
            outlined
            dense
            label="Entidad jurídica"
          />
        </v-col>

        <v-col cols="12" md="3">
          <v-text-field
            v-model="provider.ProviderLegalNRC"
            outlined
            dense
            label="NRC Legal"
          />
        </v-col>

        <v-col cols="12" md="3">
          <v-text-field
            v-model="provider.ProviderLegalNIT"
            outlined
            dense
            label="NIT Legal"
          />
        </v-col>

        <v-col cols="12" md="3">
          <v-btn class="btn-add mb-1" @click="showExpenseSuppliers = true"
            >Buscar proveedores</v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <h3 :class="showBgcolorNotes" style="text-align: center; margin-bottom: 10px;" @click="showComments = !showComments">
            Notas internas de la factura
          </h3>
        </v-col>
        <v-col cols="12" md="6">
          <h3 class="tabs" style="text-align: center" @click="showInfoInvoice = !showInfoInvoice">
            Datos de la factura del proveedor
          </h3>
        </v-col>
      </v-row>
    </div>

    <!-- Add ExpenseCode -->
    <div class="white box pa-6">
      <v-row style="margin-top: 1rem; margin-bottom: 1rem;">
        <v-col cols="12" md="2">
          <v-btn @click="addAccountingCode()" class="btn-add">
            Buscar cuenta
          </v-btn>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field dense outlined label="Código contable seleccionado" v-model="account.AccountCode" disabled></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field dense outlined label="Cuenta contable seleccionada" v-model="account.CatalogName" disabled></v-text-field>
        </v-col>
      </v-row>
      
      <v-row class="mb-4" style="padding: 0 10px;">
        <v-col cols="4" md="1" sm="2" xs="4">
          <v-btn class="btn-add" @click="addExpense2">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-col>
        <v-col v-if="invoice.InvoiceType == 'FE'" cols="12" md="3">
          <v-select
          outlined
          dense
          v-model="invoice.CodDocAsociado"
          attach
          :items="codigosAsociadosEx"
          item-value="id"
          item-text="valor"
          label="Código de documento asociado *"
          :rules="required"
          required
          >
          </v-select>
        </v-col>
        <v-col v-if="invoice.InvoiceType == 'FE'" cols="12" md="3">
          <v-text-field
          v-model="invoice.DocAsociado"
          dense
          outlined
          label="Documento asociado *"
          :rules="required"
          required
          ></v-text-field>
        </v-col>
        <v-col v-if="invoice.InvoiceType == 'FE'" cols="12" md="3">
          <v-text-field
          v-model="invoice.DetalleDocAsociado"
          dense
          outlined
          label="Detalle de documento asociado *"
          :rules="required"
          required
          ></v-text-field>
        </v-col>
        <v-col v-if="invoice.InvoiceType == 'FE'" cols="12" md="1">
          <v-text-field
          v-model="flete"
          type="number"
          dense
          outlined
          label="Flete *"
          :rules="required"
          required
          ></v-text-field>
        </v-col>
        <v-col v-if="invoice.InvoiceType == 'FE'" cols="12" md="1">
          <v-text-field
          v-model="seguro"
          type="number"
          dense
          outlined
          label="Seguro *"
          :rules="required"
          required
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- Add ExpenseCode -->

      <!-- Table -->
          <DxDataGrid
            ref="gridContainer"
            :data-source="incomeExpenses"
            :show-row-lines="true"
            :show-borders="true"
            :row-alternation-enabled="true"
            @row-updated="calculateTotalOnEnter()"
            :scrolling="{useNative: true}"
            width="100%"
          >
            <DxEditing :allow-updating="true" mode="cell" />
          
            <DxPaging :page-size="10" />
            <DxColumn
              :allow-editing="true"
              data-field="ExpenseName"
              caption="Nombre de Gasto"
              width="250"
            ></DxColumn>
            <DxColumn
              data-type="number"
              data-field="quantity"
              caption="Cantidad"
              :width="columnWidth"
            >
              <DxPatternRule
                :pattern="itemQtyPatternTwo"
                message="La cantidad solo puede contener números enteros mayores a 0 sin decimales."
              />
            </DxColumn>
            <DxColumn
              data-type="number"
              data-field="pricePerUnit"
              caption="Precio Unitario"
              :width="columnWidth"
            >
              <DxPatternRule
                :pattern="itemQtyPatternOne"
                message="La cantidad solo puede contener números enteros mayores a 0 y 2 decimales."
              />
            </DxColumn>
            <DxColumn
              :allow-editing="false"
              cell-template="IsExento"
              caption="Exento"
              :width="100"
            ></DxColumn>
            <template #IsExento="{ data }">
              <input type="checkbox" @click="data.data.IsNoSujeto = false; removeIva(data.data)" v-model="data.data.IsExento" />
            </template>

            <DxColumn
              :allow-editing="false"
              cell-template="IsNoSujeto"
              caption="No Sujeto"
              :width="100"
            ></DxColumn>
            <template #IsNoSujeto="{ data }">
              <input type="checkbox" @click="data.data.IsExento = false; removeIva(data.data)" v-model="data.data.IsNoSujeto" />
            </template>

            <DxColumn
              :allow-editing="false"
              cell-template="IsHidrocarburo"
              caption="Hidrocarburo"
              :width="100"
            ></DxColumn>
            <template #IsHidrocarburo="{ data }">
              <input type="checkbox" @click="data.data.IsHidrocarburo = false; calculateHidrocarburo(data.data)" v-model="data.data.IsHidrocarburo" />
            </template>

            <DxColumn
              :allow-editing="false"
              data-field="total"
              caption="Total"
              :width="columnWidth"
            ></DxColumn>
            <DxColumn
            :width="columnWidth"
              :allow-editing="false"
              cell-template="delete-template"
              caption=""
            ></DxColumn>
            <template #total-template="{ data }">
              {{ rowTotal(data.data) }}
            </template>
            <template #delete-template="{ data }">
              <a class="mx-1" @click="removeRow(data.data)">
                <v-icon color="error">mdi-delete</v-icon>
              </a>
            </template>
          </DxDataGrid>
        <!--/GeneralFilter-->
      
      <!-- Table -->

      <v-row class="my-5 d-flex justify-center align-center">
        <!-- Shadow footer -->
        <diV class="box" :class="isDrawerOn ? 'minimize' : 'maximize'" style="width: 100%;">
          <v-row class=" my-1 d-flex justify-center align-center">
            
            <div class="break-line"></div>
            <v-col cols="1" xxl="1" xl="1" lg="1" md="1" sm="1" xs="1" class="d-flex justify-center spaced">
              <div class="d-flex flex-column justify-center">
                <span class="text-center totalText">Subtotal</span>
                <input
                  type="text"
                  class="formControl"
                  :value="subTotal"
                  disabled
                  aria-describedby="VENTAS-TOTAL"
                />
              </div>
            </v-col>
            <v-col v-if="invoice.InvoiceType == 'CCF'" cols="1" xxl="1" xl="1" lg="1" md="1" sm="1" xs="1" class="d-flex justify-center spaced">
              <div class="d-flex flex-column justify-center">
                <span class="text-center totalText">Iva</span>
                <input
                  type="text"
                  class="formControl"
                  :value="iva"
                  disabled
                  aria-describedby="VENTAS-TOTAL"
                />
              </div>
            </v-col>
            <v-col cols="1" xxl="1" xl="1" lg="1" md="1" sm="1" xs="1" class="d-flex justify-center spaced">
              <div class="d-flex flex-column justify-center">
                <span class="text-center totalText">Exentas</span>
                <input
                  type="text"
                  class="formControl"
                  :value="exentas"
                  disabled
                  aria-describedby="VENTAS-TOTAL"
                />
              </div>
            </v-col>
            <v-col cols="1" xxl="1" xl="1" lg="1" md="1" sm="1" xs="1" class="d-flex justify-center spaced">
              <div class="d-flex flex-column justify-center">
                <span class="text-center totalText">No sujetas</span>
                <input
                  type="text"
                  class="formControl"
                  :value="nosujetas"
                  disabled
                  aria-describedby="VENTAS-TOTAL"
                />
              </div>
            </v-col>
            <v-col v-if="invoice.InvoiceType == 'FNS'" cols="1" xxl="1" xl="1" lg="1" md="1" sm="1" xs="1" class="d-flex justify-center spaced">
              <div class="d-flex flex-column justify-center">
                <span class="text-center totalText">Rete renta</span>
                <input
                  type="text"
                  class="formControl"
                  :value="reterenta"
                  disabled
                  aria-describedby="VENTAS-TOTAL"
                />
              </div>
            </v-col>
            <v-col v-if="ivaret > 0" cols="1" xxl="1" xl="1" lg="1" md="1" sm="1" xs="1" class="d-flex justify-center spaced">
              <div class="d-flex flex-column justify-center">
                <span class="text-center totalText">IVA Ret.</span>
                <input
                  type="text"
                  class="formControl"
                  :value="ivaret"
                  disabled
                  aria-describedby="VENTAS-TOTAL"
                />
              </div>
            </v-col>
            <v-col v-if="ivaperc > 0" cols="1" xxl="1" xl="1" lg="1" md="1" sm="1" xs="1" class="d-flex justify-center spaced">
              <div class="d-flex flex-column justify-center">
                <span class="text-center totalText">IVA Perc.</span>
                <input
                  type="text"
                  class="formControl"
                  :value="ivaperc"
                  disabled
                  aria-describedby="VENTAS-TOTAL"
                />
              </div>
            </v-col>
            <v-col v-if="fovial > 0" cols="1" xxl="1" xl="1" lg="1" md="1" sm="1" xs="1" class="d-flex justify-center spaced">
              <div class="d-flex flex-column justify-center">
                <span class="text-center totalText">Fovial</span>
                <input
                  type="text"
                  class="formControl"
                  :value="fovial"
                  disabled
                  aria-describedby="VENTAS-TOTAL"
                />
              </div>
            </v-col>
            <v-col v-if="cotrans > 0" cols="1" xxl="1" xl="1" lg="1" md="1" sm="1" xs="1" class="d-flex justify-center spaced">
              <div class="d-flex flex-column justify-center">
                <span class="text-center totalText">Cotrans</span>
                <input
                  type="text"
                  class="formControl"
                  :value="cotrans"
                  disabled
                  aria-describedby="VENTAS-TOTAL"
                />
              </div>
            </v-col>
            <div class="break-line2"></div>
            <v-col cols="2" xxl="2" xl="2" lg="2" md="2" sm="2" xs="2" class="d-flex justify-center" style="margin-right: 10px;">
              <div class="d-flex flex-column justify-center">
                <span class="text-center totalText">Total</span>
                <input
                  type="text"
                  class="totalDesign formControl"
                  :value="grandTotal"
                  disabled
                  aria-describedby="VENTAS-TOTAL"
                />
              </div>
            </v-col>
            <div class="break-line"></div>
            <v-col cols="2" xxl="2" xl="2" lg="2" md="2" sm="2" xs="2" class="d-flex justify-center">
              <v-btn class="text-uppercase btnclean mx-5" @click.prevent="clear">
                <v-icon>mdi-broom</v-icon>
              </v-btn>
              <v-btn
                title="Guardar ingreso de gasto"
                class="text-uppercase btnsave mx-5"
                @click.prevent="saveExpenseEntry"
                v-if="$store.getters['rolesUser/getCustomRole'](217)"
              >
                <v-icon>mdi-content-save</v-icon>
              </v-btn>
            </v-col>
            <div class="break-line"></div>
          </v-row>
        </diV>
        <!-- Shadow footer -->
      </v-row>

    </div>

    

    <!-- Dialog -->
    <v-dialog v-model="showComments" width="90%">
      <v-card class="border" style="height: 70%;">
        <v-btn @click="showComments = false" elevation="0" color="red" class="close-btn">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title class="text-h5">Notas internas de la factura</v-card-title>
        <v-card-text style="height: 80%;">
          <quill-editor
          style="height: 80%;"
          :content="invoice.InvoiceComment"
          @change="onEditorChange($event)"
          ></quill-editor>
        </v-card-text>
        <v-card-actions>
          <v-col class="text-right">
            <v-btn @click="showComments = !showComments" class="btndisable">
              Continuar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog -->
    <v-dialog v-model="showInfoInvoice" width="70%" scrollable>
      <v-card class="border "  width="100%" height="450">
        <v-btn @click="showInfoInvoice = false" elevation="0"  width="64px" color="red" class="close-btn align-self-end">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-card-title class="text-h5">Datos de la factura</v-card-title>
        <v-card-text>
            <v-row class="mt-6 ml-2 mr-2 mb-6">
              <v-col cols="12" md="3">
                <center>
                  <v-img :src="url" width="100px" height="100px" class="preview-avatar"></v-img>
                </center>
                <v-file-input @change="Preview_image" v-model="image" label="Imágen de Factura" prepend-icon="mdi-camera" placeholder="Imágen de Factura" :rules="[maxFileSize]"></v-file-input>
              </v-col>

              <v-col cols="12" md="9">
                <v-row>
                  <v-col cols="12" md="6">
                    <date-picker label="Fecha de la Factura" v-model="invoice.InvoiceDate"
                    @dateSelected="(param) => (invoice.InvoiceDate = param)"></date-picker>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-select outlined dense :items="catalogTypedocument" attach label="Tipo de Factura" v-model="invoice.InvoiceType" required
                    ></v-select>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6">
                    <v-autocomplete outlined dense v-model="invoice.InvoiceDocumentType"
                      :items="documentOptions" :items-value="value" :items-text="text" attach
                      label="Tipo de documento" required></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field outlined dense v-model="invoice.InvoiceCorrelative" required 
                      :label="invoice.InvoiceDocumentType == '2' ? 'Código de generación' : 'Correlativo de factura'"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6">
                    <v-autocomplete outlined dense v-model="invoice.InvoicePaymentMethod"
                      :items="paymentMethod" attach label="Método de Pago" required></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-autocomplete outlined dense v-model="invoice.InvoiceState" :items="invoiceStatus"
                      label="Estado de la Factura" attach required></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6">
                    <v-autocomplete outlined dense v-if="invoice.InvoiceState == 'Crédito'"
                      v-model="invoice.InvoiceDays" :items="days" label="Plazo" attach></v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog -->
    <v-dialog v-model="showExpenseSuppliers" scrollable width="70%">
      <v-card class="border">
        <v-btn @click="showExpenseSuppliers = false" elevation="0" color="red" class="close-btn align-self-end"><v-icon>mdi-close</v-icon></v-btn>
        <v-card-title>Proveedores de gastos</v-card-title>
        <v-card-text>
          <ProvidersTable
            icon="plus"
            :newProviders="providers"
            @handleProvider="addProvider"
          />
          <div class="d-flex justify-end">
            <v-btn @click="showExpenseSuppliers = false" class="btndisable">
              Continuar
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Dialog -->

    <!-- Dialog -->
    <v-dialog v-model="showExpenses" scrollable width="60%">
      <v-card class="border">
        <v-btn @click="showExpenses = false" elevation="0" color="red" class="close-btn align-self-end"><v-icon>mdi-close</v-icon></v-btn>
        <v-card-title>Gastos</v-card-title>
        <v-card-text>
          <ExpenseTable icon="plus" :newExpenses="expenses" @handleExpense="addExpense" />
          <div class="d-flex justify-end">
            <v-btn @click="showExpenses = false" class="btndisable"> Continuar </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showAccountingForm" scrollable width="70%">
      <ExpenseDialog
      :win="win"
      :newAccountList="accountList"
      :newSelectedExpense="selectedExpense"
      :action="action"
      @handleAction="handleAction"
      />
    </v-dialog>
    <!-- Dialog -->

    <!-- Alert -->
    <alerts
      v-if="alert.show"
      v-on:close_alert="closeAlert"
      v-on:accept_alert="acceptAlert"
      :properties="alert"
      class="deep-alert"
    >
    </alerts>
    <!-- Alert -->
  </div>
</template>

<script>
import Alerts from "@/components/Alerts";
import Block from "@/components/Block";
//import GeneralFilter from "@/components/GeneralFilter";
import ExpenseTable from "../ExpenseTable.vue";
import ProvidersTable from "../ExpenseProvidersTable.vue";
import DatePicker from "../../../components/DatePicker";
import ExpenseDialog from "../ExpenseDialog.vue";
//import AccountingAssign from "./AccountingAssign.vue";

import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxEditing,
  DxPatternRule
} from "devextreme-vue/data-grid";

export default {
  name: "ExpenseManagmentEntry",

  components: {
    DxDataGrid,
    DxColumn,
    ExpenseDialog,
    DxPaging,
    //GeneralFilter,
    ExpenseTable,
    ProvidersTable,
    DxEditing,
    DxPatternRule,
    Alerts,
    Block,
    DatePicker,
    //AccountingAssign
  },

  props: ["win"],

  data() {
    return {
      itemQtyPatternOne: /^(0*[1-9][0-9]*(\.[0-9]{1,2})?|0+\.[0-9]*[1-9][0-9]*)$/,
      itemQtyPatternTwo: /^(0*[1-9][0-9]*)$/,
      showComments: false,
      showInfoInvoice: false,
      showExpenseSuppliers: false,
      showExpenses: false,
      expenses: null,
      providers: null,
      provider: {
        ID_Provider: null,
        ProviderName: "",
        ProviderLegalAddress: "",
        ProviderLegalSector: "",
        ProviderLegalTaxPayerSize: "",
        ProviderLegalEntity: "",
        ProviderLegalNRC: "",
        ProviderLegalNIT: "",
      },
      insertCode: null,
      suppliers: null,
      incomeExpenses: [],
      search: {
        filter: null,
      },
      invoice: {
        InvoiceDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
        InvoiceType: "",
        InvoiceCorrelative: "",
        InvoiceDocumentType: "",
        InvoicePaymentMethod: "",
        InvoiceState: "",
        InvoiceComment: "",
        InvoiceDays: "",
        ID_AccountCatalog: null,
      },
      image: null,
      url: null,
      grandTotal: parseFloat(0).toFixed(2),
      subTotal: parseFloat(0).toFixed(2),
      exentas: parseFloat(0).toFixed(2),
      nosujetas: parseFloat(0).toFixed(2),
      iva: parseFloat(0).toFixed(2),
      reterenta: parseFloat(0).toFixed(2),
      ivaret: parseFloat(0).toFixed(2),
      ivaperc: parseFloat(0).toFixed(2),
      fovial: parseFloat(0).toFixed(2),
      cotrans: parseFloat(0).toFixed(2),
      flete: parseFloat(0).toFixed(2),
      seguro: parseFloat(0).toFixed(2),
      alert: {
        type: "success",
        show: false,
        header: "",
        body: "",
      },
      loading: false,
      showBgcolorNotes: "tabs",
      catalogTypedocument: [],
      documentOptions: [
        { value: "1", text: "Físico" },
        { value: "2", text: "Electrónico" },
      ],
      paymentMethod: [
        "Efectivo",
        "Cheque",
        "Transferencia",
        "Tarjeta de Crédito",
        "Otro",
      ],
      invoiceStatus: ["Pendiente de pago", "Cancelada", "Crédito", "Anulada"],
      days: ['8 Dias', '15 Dias', '30 Dias', '90 Dias', '120 Dias', '180 Dias'],
      validImageSize: true,
      taxPayerSize: null,
      accountList: {},
      selectedExpense: {
        ExpenseName: null,
        ID: null,
        CatalogName: null,
      },
      showAccountingForm: false,
      action: {
        ADD: false,
        EDIT: false,
        DEL: false,
      },
      expenseIdCounter: 1,
      account: {},
      required: [(v) => !!v || "Este campo es requerido"],
      codigosAsociadosEx: [
        {
          id: 4,
          valor: "Transporte",
        },
        {
          id: 1,
          valor: "Emisor",
        },
        {
          id: 2,
          valor: "Receptor",
        },
      ],
    };
  },

  mounted() {
    this.getAllExpense();
    this.getAllProviders();
    this.getCatalogTypedocument();
    this.getInfoBranch();
    this.getAllAcounts();
  },

  watch: {
    incomeExpenses: {
      handler: "calculateGrandTotal",
      deep: true,
    },
    "invoice.InvoiceType": {
      deep: true,
      immediate: true,
      handler() {
        this.calculateGrandTotal();
      } 
    },
    flete: {
      deep: true,
      immediate: true,
      handler() {
        this.calculateGrandTotal();
      } 
    },
    seguro: {
      deep: true,
      immediate: true,
      handler() {
        this.calculateGrandTotal();
      } 
    },
    "provider.ProviderLegalTaxPayerSize"() {
      this.calculateGrandTotal()
    },
  },

  computed: {
    columnWidth() {
 return this.$vuetify.breakpoint.smAndDown ? "150px" : "auto !important";
    },
    expensesNameAndCode() {
      return this.expenses
        ? this.expenses.map((item) => ({
            labelName: `${item.ID_Admin_Expenses} - ${item.ExpenseName}`,
            ...item,
          }))
        : [];
    },
    isDrawerOn() {
      return this.$store.getters.getDrawerMini;
    },
  },

  methods: {
    addAccountingCode() {
      this.showAccountingForm = true
      this.action.ADD = true
    },  
    getAllAcounts() {
      try {
        this.$API.branchaccountcatalog
          .getActiveBranchAccountCatalog()
          .then(({ data }) => {
            this.accountList = data;
          });
      } catch (error) {
        console.log(error);
      }
    },
    handleAction({ action, ...data } = {}) {
      console.log(action)
      this.showAccountingForm = false
      if(action === "error") {
        this.showAlert("warning", "Advertencia", "Código contable seleccionado es invalido.")
      }
      else {
        this.account = data
      }
    },
    async getInfoBranch() {
      let idbusiness =
        JSON.parse(localStorage.getItem("user")).businessid || "";
      let idbranch = JSON.parse(localStorage.getItem("user")).branch || "";
      this.$API.branches
        .findSpecificBranch({ branch: idbranch, business: idbusiness })
        .then((res) => {
          const infobusiness = res[0];
          /*this.datatoprint.branch.AuthFeMode = infobusiness.AuthFeMode;
          this.datatoprint.branch.BusinessBillingNRC =
            infobusiness.BusinessBillingNRC;
          this.datatoprint.branch.BusinessBillingNIT =
            infobusiness.BusinessBillingNIT;
          this.datatoprint.branch.BusinessBillingName =
            infobusiness.BusinessBillingName;*/
          this.taxPayerSize = infobusiness.BusinessTaxPayerSize;
        });
    },
    removeIva(data) {
      setTimeout(() => {
        if (data.IsExento || data.IsNoSujeto) {
          data.total = parseFloat(parseFloat(parseFloat(data.pricePerUnit * data.quantity) / 1.13).toFixed(4))
        }
        else {
          data.total = parseFloat(this.calculateTotal(data.pricePerUnit, data.quantity));
        }
        
        this.$refs.gridContainer.instance.refresh();

        this.calculateGrandTotal();
      }, 0)
    },
    calculateHidrocarburo(data) {
      setTimeout(() => {
        if (data.IsHidrocarburo) {
          this.fovial = parseFloat(data.quantity * 0.2).toFixed(2)
          this.cotrans = parseFloat(data.quantity * 0.1).toFixed(2)
        }
        else {
          if (this.fovial > 0) {
            this.fovial = parseFloat(parseFloat(this.fovial) - (data.quantity * 0.2)).toFixed(2)
          }

          if (this.cotrans > 0) {
            this.cotrans = parseFloat(parseFloat(this.cotrans) - (data.quantity * 0.1)).toFixed(2)
          }
        }

        this.$refs.gridContainer.instance.refresh();
        
        this.calculateGrandTotal();
      }, 0)
    },
    getCatalogTypedocument() {
      this.$API.catalogtypedocument
        .getCatalogTypeDocumentForProvider()
        .then(({ data }) => {
          this.catalogTypedocument = data;
        });
    },
    
    onEditorChange(e) {
      this.invoice.InvoiceComment = e.html;
      if (this.invoice.InvoiceComment != "") {
        this.showBgcolorNotes = "tabsContent";
      } else if (
        this.invoice.InvoiceComment == "" ||
        this.invoice.InvoiceComment == "<p><br></p>"
      ) {
        this.showBgcolorNotes = "tabs";
      } else {
        this.showBgcolorNotes = "tabs";
      }
    },
    Preview_image() {
      this.url = URL.createObjectURL(this.image);
    },
    acceptAlert() {
      this.alert.show = false;
    },
    closeAlert() {
      this.alert.show = false;
    },
    showAlert(type, header, body, options = null) {
      type = type == null ? "danger" : type;
      this.alert.type = type;
      this.alert.header = header;
      this.alert.body = body;
      this.alert.show = true;
      this.alert.options = options != null ? options : null;
    },
    async saveExpenseEntry() {
      if (this.checkImageSize()) return;
      
      if (this.provider.ID_Provider === null && this.provider.ID_Provider === undefined) {
        this.showAlert(
          "warning",
          "Advertencia",
          `Agregue un proveedor de gasto para guardar la factura.`
        );
        return;
      }
      if (this.incomeExpenses === null && this.incomeExpenses === undefined) {
        this.showAlert(
          "warning",
          "Advertencia",
          `Agregue gastos para guardar la factura.`
        );
        return;
      }
      if (this.grandTotal == 0) {
        this.showAlert(
          "warning",
          "Advertencia",
          `Agregue precios y unidades respectivas antes de guardar la factura.`
        );
        return;
      }

      if (this.invoice.InvoiceType === null || this.invoice.InvoiceType === "") {
        this.showAlert(
          "warning",
          "Advertencia",
          `Seleccione un tipo de factura en la sección de datos de la factura.`
        );
        return;
      }

      if (this.invoice.InvoiceDocumentType === null || this.invoice.InvoiceDocumentType === "") {
        this.showAlert(
          "warning",
          "Advertencia",
          `Seleccione tipo de documento en la sección de datos de la factura.`
        );
        return;
      }

      if (this.invoice.InvoiceCorrelative === null || this.invoice.InvoiceCorrelative === "") {
        this.showAlert(
          "warning",
          "Advertencia",
          `Ingrese el correlativo o código de generación en la sección de datos de la factura.`
        );
        return;
      }

      if (this.invoice.InvoicePaymentMethod === null || this.invoice.InvoicePaymentMethod === "") {
        this.showAlert(
          "warning",
          "Advertencia",
          `Seleccione un método de pago en la sección de datos de la factura.`
        );
        return;
      }

      if (this.invoice.InvoiceState === null || this.invoice.InvoiceState === "") {
        this.showAlert(
          "warning",
          "Advertencia",
          `Seleccione el estado de la factura en la sección de datos de la factura.`
        );
        return;
      }

      if (this.invoice.InvoiceState === 'Crédito' && (this.invoice.InvoiceDays === null || this.invoice.InvoiceDays === "") ) {
        this.showAlert(
          "warning",
          "Advertencia",
          `Seleccione los días de crédito en la sección de datos de la factura.`
        );
        return;
      }

      if ( this.invoice.InvoiceType === 'FE' && (this.invoice.CodDocAsociado === null || this.invoice.CodDocAsociado === "") && 
      (this.invoice.DocAsociado === null || this.invoice.DocAsociado === "") && 
      (this.invoice.DetalleDocAsociado === null || this.invoice.DetalleDocAsociado === "")) {
        this.showAlert(
          "warning", 
          "Advertencia", 
          "Faltan datos requeridos a completar para la factura de exportación"
        )
      }
      //console.log(this.incomeExpenses);

      const formData = new FormData()

      const expensesEntry = {
        ID_Provider: this.provider.ID_Provider,
        ID_AccountCatalog: this.account.ID_AccountCatalog,
        CatalogName: this.account.CatalogName,
        ExpenseInvoiceDate: this.invoice.InvoiceDate,
        ExpenseInvoiceType: this.invoice.InvoiceType,
        ExpenseInvoiceDocumentType: this.invoice.InvoiceDocumentType,
        ExpenseInvoiceId: this.invoice.InvoiceCorrelative,
        ExpenseInvoicePayment: this.invoice.InvoicePaymentMethod,
        ExpenseInvoiceStatus: this.invoice.InvoiceState,
        ExpenseInvoiceCreditDays: this.invoice.InvoiceDays,
        ExpenseInvoiceComment: this.invoice.InvoiceComment,
        Iva: this.iva,
        Exentas: this.exentas,
        NoSujetas: this.nosujetas,
        ReteRenta: this.reterenta,
        IvaRet: this.ivaret,
        IvaPerc: this.ivaperc,
        Fovial: this.fovial,
        Cotrans: this.cotrans,
        Flete: this.flete,
        Seguro: this.seguro,
        SubTotal: this.subTotal,
        TotalExpense: this.grandTotal,
        CodDocAsociado: this.invoice.CodDocAsociado,
        DocAsociado: this.invoice.DocAsociado,
        DetalleDocAsociado: this.invoice.DetalleDocAsociado,
      };
      
      formData.append("expensesEntry", JSON.stringify(expensesEntry))
      formData.append("file", this.image)

      await this.saveAccountingExpenseEntry(formData, this.incomeExpenses);

      this.clear();
    },
    saveAccountingExpenseEntry(expensesEntry, expensesEntryDetail) {
      this.loading = true;
      this.$API.accountingexpensesentry
        .createAccountingExpensesEntry(expensesEntry)
        .then((data) => {
          this.saveAccountingExpenseEntryDetail(expensesEntryDetail, data.ID);
        })
        .catch((error) => {
          this.loading = false;
          this.showAlert("danger", "Error", `${error}`);
        });
    },
    saveAccountingExpenseEntryDetail(expensesEntryDetail, ID_Entry) {
      this.$API.accountingexpensesentrydetail
        .createAccountingExpensesEntryDetail({ expensesEntryDetail, ID_Entry })
        .then(() => {
          this.loading = false;
          this.showAlert(
            "success",
            "Ingreso de gasto de agregado",
            `Se agregó el ingreso de gasto correctamente`
          );
        })
        .catch((error) => {
          this.loading = false;
          this.showAlert("danger", "Error", `${error}`);
        });
    },
    getAllExpense() {
      this.$API.accountingexpenses
        .getAllAccountingExpenses()
        .then(({ data }) => {
          this.expenses = data;
        })
        .catch((error) => console.log(error));
    },
    getAllProviders() {
      this.$API.accountingexpenses
        .getAllProvidersExpenses()
        .then(({ data }) => {
          this.providers = data;
        })
        .catch((error) => console.log(error));
    },
    filterHandler({ data }) {
      this.expenses = data[0];
    },
    filterHandlerExpense({ data }) {
      this.expenses = data[0];
    },
    addExpense(expense) {
      let findExpense;
      if (this.isNumber(expense)) {
        // When it was sent from v-dialog as a number (ID)
        expense = this.expenses.find((item) => item.ID === expense);
        findExpense = this.checkIfAlreadyExistInTable(expense);
      } else {
        // When it was sent from table component as an object
        findExpense = this.checkIfAlreadyExistInTable(expense);
      }

      // If it was already add in the table, add it
      if (!findExpense) {
        expense.pricePerUnit = 0;
        expense.quantity = 1;
        expense.IsExento = false;
        expense.IsNoSujeto = false;
        expense.total = 0;
        this.incomeExpenses.push(expense);
      }   
      this.insertCode = null;
    },
    addExpense2() {
      let expense = {
        expenseIdCounter: this.expenseIdCounter++,
        ID_AccountCatalog: null,
        CatalogName: null,
        ExpenseName: 'Ingresar nombre de gasto aquí',
        pricePerUnit: 0,
        quantity: 1,
        IsExento: false,
        IsNoSujeto: false,
        total: 0,
      }
      
      this.incomeExpenses.push(expense);
      console.log(this.incomeExpenses);
    },
    checkIfAlreadyExistInTable(expense) {
      return this.incomeExpenses.find((incomeExpense) => incomeExpense.ID === expense.ID);
    },
    addProvider(data) {
      this.provider = data;
      this.showExpenseSuppliers = false
    },
    removeRow(data) {
      const index = this.incomeExpenses.findIndex((income) => income.ID === data.ID);
      if (index !== -1) {
        this.incomeExpenses.splice(index, 1);
      }
    },
    isNumber(value) {
      return typeof value === "number";
    },
    calculateTotalOnEnter() {
      this.incomeExpenses.map((incomeExpense) => {
        if (incomeExpense.IsExento || incomeExpense.IsNoSujeto) {
          incomeExpense.total = parseFloat(
            parseFloat(
              parseFloat(this.calculateTotal(incomeExpense.pricePerUnit, incomeExpense.quantity)) / 1.13
            ).toFixed(4)
          );
        }
        else {
          incomeExpense.total = parseFloat(
            this.calculateTotal(incomeExpense.pricePerUnit, incomeExpense.quantity)
          );
        }
      });
      this.calculateGrandTotal();
    },
    calculateTotal(price, quantity) {
      if (this.isNumber(price) && this.isNumber(quantity)) {
        return parseFloat(price * quantity).toFixed(2);
      }
      return "error";
    },
    calculateGrandTotal() {
      let subTotal = 0;
      let iva = 0;
      let exentas = 0;
      let nosujetas = 0;
      let reterenta = 0;
      let ivaret = 0;
      let ivaperc = 0;
      for (const item of this.incomeExpenses) {
        if (item.IsExento) {
          exentas += parseFloat(item.total);
        }
        else if (item.IsNoSujeto) {
          nosujetas += parseFloat(item.total);
        }
        else {
          if (this.invoice.InvoiceType == 'CCF') {
            subTotal += parseFloat(parseFloat(item.total) / 1.13);
            iva += parseFloat(parseFloat(item.total) - parseFloat(parseFloat(item.total) / 1.13))
          }
          else {
            subTotal += parseFloat(item.total);
            iva += parseFloat(parseFloat(item.total) - parseFloat(parseFloat(item.total) / 1.13))
          }

          if (this.invoice.InvoiceType == 'FNS') {
            reterenta += parseFloat(parseFloat(item.total) * 0.01)
          }
        }
      }


      if (this.invoice.InvoiceType == 'FCF') {
        if ((this.provider.ProviderLegalTaxPayerSize == "PEQUEÑO" && (this.taxPayerSize == "GRANDE" || this.taxPayerSize == "MEDIANO")) || (this.provider.ProviderLegalTaxPayerSize == "MEDIANO" && this.taxPayerSize == "GRANDE")) {
          if (subTotal >= 113) {
            let subTotalLessIva = parseFloat(parseFloat(subTotal) / 1.13);
            ivaret = parseFloat(parseFloat(subTotalLessIva) * 0.01)
          } 
        }
      }

      if (this.invoice.InvoiceType == 'CCF') {
        if ((this.taxPayerSize == "PEQUEÑO" && (this.provider.ProviderLegalTaxPayerSize == "GRANDE" || this.provider.ProviderLegalTaxPayerSize == "MEDIANO")) || (this.taxPayerSize == "MEDIANO" && this.provider.ProviderLegalTaxPayerSize == "GRANDE")) {
          if (subTotal >= 100) {
            ivaperc = parseFloat(parseFloat(subTotal) * 0.01)
          }

          ivaret = 0
        }

        if ((this.provider.ProviderLegalTaxPayerSize == "PEQUEÑO" && (this.taxPayerSize == "GRANDE" || this.taxPayerSize == "MEDIANO")) || (this.provider.ProviderLegalTaxPayerSize == "MEDIANO" && this.taxPayerSize == "GRANDE")) {
          if (subTotal >= 100) {
            ivaret = parseFloat(parseFloat(subTotal) * 0.01)
          }

          ivaperc = 0
        }
      }

      this.subTotal = subTotal.toFixed(2);
      this.iva = iva.toFixed(2);
      this.exentas = exentas.toFixed(2);
      this.nosujetas = nosujetas.toFixed(2);
      this.reterenta = reterenta.toFixed(2);
      this.ivaret = ivaret.toFixed(2);
      this.ivaperc = ivaperc.toFixed(2);

      if (this.invoice.InvoiceType == "CCF") {
        this.grandTotal = parseFloat(
          subTotal + iva + exentas + nosujetas - reterenta - ivaret + ivaperc + parseFloat(this.fovial) + parseFloat(this.cotrans)
        ).toFixed(2)
      }
      else if (this.invoice.InvoiceType == "FE"){
        this.grandTotal = parseFloat(
          subTotal + exentas + nosujetas - reterenta - ivaret + ivaperc + parseFloat(this.fovial) + parseFloat(this.cotrans) 
          + parseFloat(this.flete) + parseFloat(this.seguro)
        ).toFixed(2)
      }
      else {
        this.grandTotal = parseFloat(
          subTotal + exentas + nosujetas - reterenta - ivaret + ivaperc + parseFloat(this.fovial) + parseFloat(this.cotrans)
        ).toFixed(2)
      }
      
    },
    clear() {
      this.incomeExpenses = [];
      this.provider = {
        ID_Provider: null,
        ProviderName: "",
        ProviderLegalAddress: "",
        ProviderLegalSector: "",
        ProviderLegalTaxPayerSize: "",
        ProviderLegalEntity: "",
        ProviderLegalNRC: "",
        ProviderLegalNIT: "",
      };
      this.account = {}
      this.invoice = {}
    },
    maxFileSize(event) {
      const allowedSize = 2 * 1024 * 1024; // 2 MB in bytes
      const fileSize = event.size;

      if (fileSize > allowedSize) {
        this.validImageSize = false;
        return `El tamaño del archivo debe ser menor a 2 Mb`;
      }

      this.validImageSize = true;
      return true;
    },
    checkImageSize() {
      if (this.validImageSize == false) {
        this.showAlert("danger", "Error", "El tamaño de la imagen debe ser menor a 2 Mb");
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped>
.bar {
  width: 100%;
  background-color: rgb(60, 60, 60) !important;
  color: white !important;
  border-style: solid;
  border-color: black;
  border-radius: 15px !important;
  margin-top: 1rem !important;
}

.box {
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 3px;
  border: 1px solid;
  border-color: black;
}

.border {
  border-radius: 15px !important;
}

.btnsave {
  background-color: #00a178 !important;
  color: white;
}

.btnclean {
  background-color: #f29d35 !important;
  color: white;
  margin: 0;
  padding: 0;
}

.btn-add {
  background-color: #3498db !important;
  color: white;
}

.close-btn {
  color: white !important;
  position: relative !important;
  float: right !important;
}

.btndisable {
  background-color: #41464c !important;
  color: white;
}

.totalDesign {
  background-color: #00a178 !important;
  font-weight: bold !important;
  color: white !important;
  font-size: 25px;
  max-width: 165px !important;
}

.formControl {
  background: #d6eaf3;
  border: 1px solid #1976d2;
  text-align: center;
  max-width: 80px;
  height: 30px;
  border-radius: 20px;
}
.totalText {
  font-size: 16px;
  margin-bottom: 5px;
}

.minimize {
  display: flex;
  min-width: calc(100% - 100px);
  background: #41464c;
  color: white;
  bottom: 0;
  left: auto;
  z-index: 99999999;
  justify-content: center;
}

.maximize {
  display: flex;
  width: calc(100% - 300px);
  background: #41464c;
  color: white;
  bottom: 0;
  left: auto;
  z-index: 99999999;
  justify-content: center;
}

.tabs {
  width: 100%;
  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-radius: 10px;
}

.tabs:hover {
  background-color: rgba(80, 80, 80, 1);
  cursor: pointer;
}

.tabsContent:hover {
  background-color: #ffb266;
  cursor: pointer;
}

.tabsContent {
  width: 100%;
  padding: 10px;
  background-color: #f29d35;
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-radius: 10px;
}
.break-line {
  display: none; /* Oculta por defecto */
}

@media (max-width: 910px) { 
  .break-line {
    display: block;
    width: 100%;
    height: 10px; /* Mantiene el div sin altura */
  }
}

.break-line2 {
  display: none; /* Oculta por defecto */
}

@media (max-width: 379px) { 
  .break-line2 {
    display: block;
    width: 100%;
    height: 10px; /* Mantiene el div sin altura */
  }
}

@media (min-width: 600px) and (max-width: 720px){ 
  .spaced {
    margin-right: 20px;
  }
}

@media (min-width: 520px) and (max-width: 599px){ 
  .spaced {
    margin-right: 40px;
  }
}
@media (min-width: 440px) and (max-width: 519px){ 
  .spaced {
    margin-right: 55px;
  }
}
@media (min-width: 380px) and (max-width: 439px){ 
  .spaced {
    margin-right: 65px;
  }
}
</style>

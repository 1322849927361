<template   >
  <v-card class="border" style="max-height:inherit; overflow-y:scroll"  >
    <v-btn @click="close" elevation="0" color="red" class="close-btn align-self-end"
      ><v-icon>mdi-close</v-icon></v-btn
    >
    <v-card-title v-if="action.ADD" class="title">
      Agregar proveedor de gasto
    </v-card-title>
    <v-card-title v-else class="title"> Modificar proveedor de gasto </v-card-title>

    <v-form @submit.prevent="action.ADD ? add : edit">
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="provider.ProviderName"
              :rules="rules"
              outlined
              dense
              required
              label="Nombre"
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="provider.ProviderLegalAddress"
              :rules="rules"
              outlined
              dense
              required
              label="Dirección legal"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <v-autocomplete
              outlined
              dense
              required
              label="Actividad economica:*"
              :items="activities"
              :rules="rules"
              attach
              v-model="provider.ProviderLegalSector"
              item-text="Valores"
              item-value="Codigo"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              outlined
              dense
              required
              :items="size"
              label="Tamaño del contribuyente"
              attach
              v-model="provider.ProviderLegalTaxPayerSize"
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <v-select
              v-model="provider.ProviderLegalEntity"
              :items="entity"
              :rules="rules"
              outlined
              dense
              required
              attach
              label="Entidad jurídica"
            ></v-select>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="provider.ProviderLegalNRC"
              :rules="rules"
              outlined
              dense
              required
              attach
              label="NRC Legal"
              @input="nrcMask"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="provider.ProviderLegalNIT"
              :rules="rules"
              outlined
              dense
              required
              label="NIT Legal"
              @input="nitMask"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn class="btn-add" v-if="action.DEL && $store.getters['rolesUser/getCustomRole'](215)" @click="del">Eliminar</v-btn>
        <v-spacer></v-spacer>
        <v-btn class="btnsave" v-if="action.ADD && $store.getters['rolesUser/getCustomRole'](213)" type="submit" @click="add">Guardar</v-btn>
        <v-btn class="btnsave" v-if="action.EDIT && $store.getters['rolesUser/getCustomRole'](214)" type="submit" @click="edit">Editar</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import {
  nit as nitMask,
  nrc as nrcMask,
  applyDUIMaskOutOfInput,
  applyNITMaskOutOfInput,
} from "@/helpers/masks";
import { mapState, mapGetters } from "vuex";
export default {
  name: "ExpenseProviderDialog",

  props: ["action", "newSelectedProvider"],

  components: {},

  data() {
    return {
      rules: [(v) => v !== null || "Valor es requerido"],
      entity: ["PERSONA NATURAL", "SOCIEDAD ANÓNIMA", "CORPORACIÓN"],
      size: ["PEQUEÑO", "MEDIANO", "GRANDE"],
      provider: this.newSelectedProvider
        ? this.newSelectedProvider
        : {
            ProviderName: "",
            ProviderLegalAddress: "",
            ProviderLegalSector: "",
            ProviderLegalTaxPayerSize: "",
            ProviderLegalEntity: "",
            ProviderLegalNRC: "",
            ProviderLegalNIT: "",
          },
    };
  },

  watch: {
    newSelectedProvider(data) {
      if (data != undefined && data != null) {
        this.provider = this.newSelectedProvider;
      } else {
        this.provider = {
          ProviderName: "",
          ProviderLegalAddress: "",
          ProviderLegalSector: "",
          ProviderLegalTaxPayerSize: "",
          ProviderLegalEntity: "",
          ProviderLegalNRC: "",
          ProviderLegalNIT: "",
        };
      }
    },
  },

  computed: {
    ...mapState("rolesUser", {
      rolesStatus: (state) => state.roles,
    }),
    ...mapGetters(["getCatalogMH"]),
    ID_Business() {
      return JSON.parse(localStorage.getItem("user")).businessid != undefined
        ? JSON.parse(localStorage.getItem("user")).businessid
        : 0;
    },
    ID_Branch() {
      return JSON.parse(localStorage.getItem("user")).branch != undefined
        ? JSON.parse(localStorage.getItem("user")).branch
        : 0;
    },
    activities() {
      return this.getCatalogMH("ActividadEconomica");
    },
  },

  methods: {
    async nrcMask() {
      this.provider.ProviderLegalNRC = await nrcMask(
        this,
        this.provider.ProviderLegalNRC
      );
    },
    async nitMask() {
      this.provider.ProviderLegalNIT = await nitMask(
        this,
        this.provider.ProviderLegalNIT
      );
    },
    applyMasks() {
      this.provider.ProviderLegalNIT &&
        (this.provider.ProviderLegalNIT = applyNITMaskOutOfInput(
          this.provider.ProviderLegalNIT
        ));
      this.provider.ProviderLegalNRC &&
        (this.provider.ProviderLegalNRC = applyDUIMaskOutOfInput(
          this.provider.ProviderLegalNRC
        ));
    },
    add() {
      this.$emit("handleAction", this.dataToSend("add"));
      this.clean();
    },
    edit() {
      this.$emit("handleAction", this.dataToSend("edit", this.newSelectedProvider));
      this.clean();
    },
    del() {
      this.$emit("handleAction", this.dataToSend("del", this.newSelectedProvider));
      this.clean();
    },
    close() {
      this.$emit("handleAction");
      this.clean();
    },
    dataToSend(action) {
      this.provider.action = action;
      return this.provider;
    },

    clean() {
      this.provider = {
        ProviderName: "",
        ProviderLegalAddress: "",
        ProviderLegalSector: "",
        ProviderLegalTaxPayerSize: "",
        ProviderLegalEntity: "",
        ProviderLegalNRC: "",
        ProviderLegalNIT: "",
      };
      this.applyMasks();
    },
  },
};
</script>

<style scoped>
.sized {
  min-width: 400px !important;
}

.close-btn {
  color: white !important;
  position: relative !important;
  float: right !important;
}

.border {
  border-radius: 15px !important;
}

.btnsave {
  background-color: #00a178 !important;
  color: white;
}

.btnclean {
  background-color: #f29d35 !important;
  color: white;
  margin: 0;
  padding: 0;
}

.btn-add {
  background-color: #3498db !important;
  color: white;
}

.btndisable {
  background-color: #41464c !important;
  color: white;
}

.btndelete {
  background-color: red !important;
  color: white;
}

.title {
  font-weight: bold !important;
  font-size: 25px !important;
}
</style>

<template>
  <div>
    <GeneralFilter
      :filterEndpoint="$API.accountingexpenses.getAllAccountingExpensesFiltered"
      :search="search"
      @emptyFilter="getAllExpense"
      @filtered="filterHandler"
    >
      <DxDataGrid
        :data-source="expenses"
        :show-row-lines="true"
        :show-borders="true"
        :row-alternation-enabled="true"
        :scrolling="{ useNative: true }"    
                    :column-auto-width="true"
      >
        
        <DxPaging :page-size="10" />
        <DxColumn :width="columnWidth" cell-template="showdata" v-if="$store.getters['rolesUser/getCustomRole'](207)"/>
        <DxColumn :width="columnWidth" caption="Código de gasto" data-field="ID_Admin_Expenses"></DxColumn>
        <DxColumn :width="columnWidth" caption="Nombre de gasto" data-field="ExpenseName"></DxColumn>
        <DxColumn :width="columnWidth" caption="Código contable" data-field="account_code"></DxColumn>
        <DxColumn caption="Cuenta contable relacionada" data-field="account_description"></DxColumn>
        <template #showdata="{ data }">
          <a href="#" @click.prevent.stop="handleExpense(data.data)">
            <v-icon>mdi-{{ icon }}</v-icon>
          </a>
        </template>
      </DxDataGrid>
    </GeneralFilter>
  </div>
</template>

<script>
import GeneralFilter from "@/components/GeneralFilter";
import { DxDataGrid, DxColumn,  DxPaging } from "devextreme-vue/data-grid";

export default {
  name: "ExpenseTable",

  props: {
    newExpenses: {
      type: Array,
      required: true,
    },
    icon: {
      type: String,
      default: "eye",
    },
  },

  data() {
    return {
      expenses: null,
      search: {
        filter: null,
      },
    };
  },

  components: {
    DxDataGrid,
    DxColumn,
    
    DxPaging,
    GeneralFilter,
  },

  mounted() {
    this.getAllExpense();
  },

  watch: {
    newExpenses(data) {
      if (data != null || data != undefined) {
        this.expenses = [...this.newExpenses];
      }
    },
  },

  methods: {
    getAllExpense() {
      this.expenses = [...this.newExpenses];
    },
    filterHandler({ data }) {
      this.expenses = data[0];
      console.log(this.expenses);
    },
    handleExpense(data) {
      if (this.icon === "eye") {
        this.selectedExpense = {
          ExpenseName: data.ExpenseName,
          ID: data.ID,
          ID_AccountCatalog: data.ID_AccountCatalog,
        };
        this.$emit("handleExpense", this.selectedExpense);
      } else {
        this.selectedExpense = data
        this.$emit("handleExpense", this.selectedExpense);
      }
    },
  },
  computed: {
    columnWidth() {
    
    return this.$vuetify.breakpoint.smAndDown ? '150px' : 'auto !important';
  },}
};
</script>

<style scoped>
.bar {
  width: 100%;
  background-color: rgb(60, 60, 60) !important;
  color: white !important;
  border-style: solid;
  border-color: black;
  border-radius: 15px !important;
  margin-top: 1rem !important;
}

.box {
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid;
  border-color: black;
}

.expense-name {
  font-weight: bold;
  font-style: oblique;
}
</style>
